<template>
  <section class="min-h-screen pt-2">
    <van-skeleton
      v-if="loading"
      class="basic-skeleton"
      :row="5"
    />
    <template v-else>
      <Basic :news="news" />
      <van-empty
        v-if="!news"
        description="无法加载社区新闻信息，请稍后再试！"
      />
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Basic from '@/components/news/basic'
import share from '@/mixins/share'
export default {
  name: 'PublicNewsViewer',
  components: {
    Basic
  },
  mixins: [share],
  data() {
    return {
      loading: true,
      processing: false,
      news: null
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb'])
  },
  async mounted() {
    await Promise.all([this.fetch(), this.prepareWx()])
    if (this.news) {
      let options = {
        title: this.news.title,
        desc: this.news.subtitle
      }
      options.imgUrl = this.news.image
      this.updateShareData(options)
    }

    if(typeof Heti != 'undefined') {
      const heti = new Heti('.heti')
      heti.autoSpacing()
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        const response = await this.tcb.callFunction({
          name: 'news',
          data: {
            $url: 'subscriberGet',
            id: this.$route.params.news
          }
        })
        // eslint-disable-next-line no-console
        console.warn('获取到相关社区新闻', response)
        if (response.result?.error) {
          this.$notify({ type: 'danger', message: response.result.error })
        } else {
          let news = response.result.data
          this.$set(this, 'news', news)
        }
      } catch (err) {
        this.$notify({ type: 'danger', message: '服务器内部错误' })
        // eslint-disable-next-line no-console
        console.error('无法获取社区新闻', err)
        this.news = null
      }
      this.$nextTick(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.basic-skeleton {
  padding: 30px 16px !important;
}
</style>
