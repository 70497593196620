<template>
  <div
    v-if="news"
    class="flex flex-col w-full mt-4"
  >
    <h3 class="mx-6 text-2xl font-bold leading-7">
      {{ news.title }}
    </h3>
    <div class="flex flex-col mx-6 mt-1">
      <div class="flex items-center mt-2 text-sm text-gray-500">
        发布时间：{{ $dayjs(news.publishedAt || news.published_at).format('YYYY-MM-DD HH:mm:ss') }}
      </div>
      <div class="flex items-center mt-2 text-sm text-gray-500">
        类别：{{ news.category }}
      </div>
    </div>
    <div
      :inner-html.prop="news.content | htmlXss"
      class="w-full braft-output-content"
      :class="news.contentClassname ? news.contentClassname : 'p-6'"
    />
    <div
      v-if="news.attachments"
      class="w-full content-container"
    >
      <p class="px-6 my-1 font-semibold">
        附件：
      </p>
      <ul
        v-if="Array.isArray(news.attachments)"
        class="px-6 pb-6 text-sm"
      >
        <li
          v-for="(item, index) of news.attachments"
          :key="item"
          class="my-1"
        >
          <a
            :href="
              link(
                item,
                news.attachment_names ? news.attachment_names[index] : null
              )
            "
          >
            {{
              news.attachment_names && news.attachment_names[index]
                ? news.attachment_names[index]
                : `附件（${index + 1}）`
            }}
          </a>
        </li>
      </ul>
      <ul
        v-else
        class="px-6 pb-6 text-sm"
      >
        <li class="my-1">
          <a
            :href="
              link(
                news.attachments,
                news.attachment_names ? news.attachment_names[0] : null
              )
            "
          >
            {{
              news.attachment_names && news.attachment_names[0]
                ? news.attachment_names[0]
                : `附件`
            }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentNewsBasic',
  props: {
    module: {
      type: String,
      default: 'public'
    },
    news: {
      type: Object,
      default: null
    }
  },
  computed: {
    link() {
      return (e, name) => {
        if (!e) {
          return ''
        }
        let url = e.substring(0, e.indexOf('?'))
        return url.endsWith('.pdf')
          ? `/pdfjs/web/viewer.html?file=${url}${
              name ? `&name=${encodeURIComponent(name)}` : ''
            }`
          : e
      }
    }
  }
}
</script>
